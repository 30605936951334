@import "../utils";

// Nissan brand theme
$nissan-primary-color: #0073BF;
$nissan-secondary-color: #fff;
$nissan-screenhead-background: #F4F5F7;
$nissan-regular-color: #7c8d98;
$nissan-contrast-color: #000;
$nissan-background-color: #f2f2f2;
$nissan-body-color: #eeefef;
$nissan-logo: '../../images/nissan_logo.png';
$nissan-logo-promo: '../../images/nissan_logo_promo.png';

/* BRAND FONTS */

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-bold-webfont.svg#nissan_brandbold') format('svg'); // Legacy iOS
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-bold-webfont.svg#nissan_brandbold') format('svg'); // Legacy iOS
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-italic-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-italic-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-italic-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-italic-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-italic-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-italic-webfont.svg#nissan_branditalic') format('svg'); // Legacy iOS
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-light-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-light-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-light-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-light-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-light-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-light-webfont.svg#nissan_brandlight') format('svg'); // Legacy iOS
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'nissan_brand';
  src: url('../fonts/nissanbrand-regular-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/nissanbrand-regular-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/nissanbrand-regular-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/nissanbrand-regular-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/nissanbrand-regular-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/nissanbrand-regular-webfont.svg#nissan_brandregular') format('svg'); // Legacy iOS
  font-weight: normal;
  font-style: normal;
}

// =========================
// Nissan specific styling
// =========================

.expand-image-modal.Nissan {
  font-family: 'nissan_brand', sans-serif;
}

div.nissan {

  & *,
  .Topics .topic-content .note * {
    font-family: 'nissan_brand', sans-serif;
  }

  .topic-content {

    p,
    .p,
    li,
    strong {
      font-family: "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    }
  }

  // Logo
  .logo {
    background-image: url($nissan-logo-promo);
  }

  .screen-logo,
  .terms-logo {
    background: none;

    &:after {
      content: "";
      display: block;
      background-image: url($nissan-logo);
      background-size: cover;
      position: absolute;
      top: 0;
      z-index: 1;
    }
  }

  .screen-logo {
    width: rem(75);

    &:after {
      width: rem(76);
      height: rem(94);
    }
  }

  .terms-logo {
    width: rem(56);
    flex: 0 0 rem(56);

    &:after {
      width: rem(56);
      height: rem(68);
    }
  }

  @include media-desktop {
    .terms-logo {
      width: rem(76);
      flex: 0 0 rem(76);
      align-self: flex-start;

      &:after {
        width: rem(76);
        height: rem(94);
      }
    }

    .screen-topmenu .topmenu-link {
      font-weight: bold;
    }
  }

  // Splash screen colors
  .BrandsSelectorScreen,
  .Promo,
  .Guide {

    background: $nissan-primary-color;
    color: $nissan-secondary-color;

    .hint {
      color: #E57593;
    }

    .screen-title {
      color: $nissan-contrast-color;
    }

    .screen-overlay {
      background: $nissan-primary-color;
      color: $nissan-secondary-color;

      .PrimaryButton {
        color: $nissan-contrast-color;
        background: $nissan-secondary-color;
      }

      .icon .fill {
        fill: rgba($nissan-secondary-color, .5);
      }
    }

    @include media-mobile() {
      .screen-topmenu {
        .topmenu-trigger .icon .fill {
          fill: $nissan-secondary-color;
        }

        .topmenu-inner {
          box-shadow: 0 rem(10) rem(15) rem(-5) rgba(0, 0, 0, 0.2);
        }
      }
    }
  }

  .Vehicles {
    @include media-desktop() {
      background: $nissan-background-color;
      color: $nissan-contrast-color;
    }
  }

  // Regular screen colors
  .Guide {
    color: $nissan-regular-color;
    background: $nissan-secondary-color;

    @include media-mobile() {
      .screen-subhead {
        box-shadow: inset 0 -3px #c71444
      }
    }


  }

  //loader styling
  .Loader {
    background-color: $nissan-primary-color;

    .loader-default {
      &>div {
        background-color: $nissan-secondary-color;
      }
    }

    .loader-ios {
      @keyframes spinner-ios-nissan {
        0% {
          background-color: $nissan-secondary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      @keyframes spinner-ios-nissan-inverse {
        0% {
          background-color: $nissan-primary-color;
        }

        100% {
          background-color: transparent;
        }
      }

      &>div {
        animation-name: spinner-ios-nissan;
      }
    }

    .loader-android {
      circle {
        stroke: $nissan-secondary-color;
      }
    }

    &.inverse {
      background-color: transparent;
      color: $nissan-regular-color;

      .loader-default>div {
        background-color: $nissan-primary-color;
      }

      .loader-ios>div {
        animation-name: spinner-ios-nissan-inverse;
      }

      .loader-android {
        circle {
          stroke: $nissan-primary-color;
        }
      }
    }
  }

  .NavBar {
    @include media-mobile() {
      background: $nissan-screenhead-background;

      .nav-link {
        color: #5E5757;

        .icon {
          .active {
            display: none;
          }

          .fill {
            fill: $nissan-regular-color;
          }

          .stroke {
            stroke: $nissan-regular-color;
          }
        }

        &.active {
          color: $nissan-primary-color;
          box-shadow: inset 0 -3px 0px -1px $nissan-primary-color;

          .icon .active {
            display: inherit;
          }

          .icon .inactive {
            display: none;
          }

          .icon .fill {
            fill: $nissan-primary-color;
          }

          .icon .stroke {
            stroke: $nissan-primary-color;
          }
        }
      }
    }
  }

  .VehicleList {
    background: $nissan-secondary-color;

    h2 {
      color: $nissan-contrast-color;
    }

    .model {
      color: $nissan-regular-color;

      .name {
        color: $nissan-contrast-color;
      }
    }
  }

  .VehicleYearSelector {
    @include media-mobile() {
      height: rem(100);

      &:before,
      &:after {
        display: block;
        font-size: rem(14);
        padding-left: rem(20);
        line-height: rem(20);
      }

      &:before {
        margin-bottom: rem(10);
      }

      &:after {
        margin-top: rem(5);
      }

      .slick-slide {
        &:first-child {
          padding-left: rem(16);
        }
      }

      .wrapper {
        height: auto;

        &>a {
          display: inline-block;
          height: rem(48);
          width: rem(80);
          background: $nissan-secondary-color;
          color: rgba(0, 0, 0, 0);
          font-size: rem(16);
          font-weight: 400;
          border-radius: rem(4);
          padding: rem(13);
          margin: 0 rem(8) rem(10);
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
          opacity: 1;
          text-align: center;

          &:after {
            display: flex;
            align-items: center;
            justify-content: center;
            content: attr(data-year);
            font-weight: normal;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: transparentize($nissan-contrast-color, 0.6);
          }

          &.current {
            transform: none;
            background: $nissan-primary-color;
            color: $nissan-secondary-color;
            font-weight: 700;

            &:after {
              display: none;
            }
          }
        }
      }
    }
  }

  .VehicleGroupSelector,
  .VehicleModelFamily {

    &:before,
    &:after {
      box-shadow: none;
    }

    .group {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04);
    }

    .group-inner {
      padding: 0 rem(20) rem(40) rem(16);
    }
  }

  .VehicleModelSelector,
  .VehicleModelFamily {
    .icon.icon-arrow-left .stroke {
      stroke: $nissan-primary-color;
    }

    .back .icon.icon-arrow-left .stroke {
      stroke: #999;
    }
  }

  .VehicleGroupFilter {
    .group>button {
      color: #767676;
    }

    .group.current>button {
      box-shadow: inset 0 -4px $nissan-primary-color;
      color: $nissan-primary-color;
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter {
    li.active {
      color: $nissan-primary-color;

      .icon .stroke {
        stroke: $nissan-primary-color;
      }
    }
  }

  .FoldersFilterDropdown {
    .dropdown-trigger {
      background-color: $nissan-primary-color;
      color: $nissan-secondary-color;
      box-shadow: inset 0 -1px rgba($nissan-secondary-color, .3);

      .icon .stroke {
        stroke: $nissan-secondary-color;
      }
    }

    .dropdown-container {
      background-color: $nissan-primary-color;

      li {
        color: $nissan-secondary-color;
      }
    }
  }

  // Buttons
  .PrimaryButton {
    color: $nissan-contrast-color;
    background: $nissan-secondary-color;
  }

  .SecondaryButton {
    color: $nissan-secondary-color;
    background: $nissan-primary-color;
    border: 1px solid rgba($nissan-secondary-color, .3);
    font-weight: 400;
  }

  .ErrorMessage.inverse,
  .TermsOfServiceModal,
  .screen-overlay {
    .PrimaryButton {
      color: $nissan-secondary-color;
      background: $nissan-primary-color;
    }
  }

  .ErrorMessage:not(.inverse) {
    background-color: $nissan-primary-color;
    color: $nissan-secondary-color;

    .icon .fill {
      fill: rgba($nissan-secondary-color, .5);
    }

    h1 {
      color: $nissan-secondary-color;
    }

    span {
      color: rgba($nissan-secondary-color, .5);
    }
  }

  .Promo {
    .screenshot {
      &.ios {
        background-image: url("../../images/screenshot-nissan-ios.png");
      }

      &.android {
        background-image: url("../../images/screenshot-nissan-android.png");
      }

      &:after {
        box-shadow: inset 0 rem(-180) rem(60) rem(-60) rgba($nissan-primary-color, 1);
      }
    }

    .browse {
      color: $nissan-secondary-color;
    }
  }

  .Topics {
    @include media-mobile {
      .topic-title {
        font-weight: bold;
      }
    }
  }

  .TopicsList {
    li .item-iconbar .icon.icon-arrow-left .stroke {
      stroke: $nissan-primary-color
    }
  }
}
