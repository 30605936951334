@import 'utils';
@import 'icons';
@import "../../node_modules/video.js/dist/video-js.min.css";
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.version {
  display: none;
}

// React router transitions
.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear;
}

.fade-exit {
  opacity: 1;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 300ms linear;
}

.container {
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  @include media-ie {
    height: 100%;
  }
}

%Screen {
  @extend .container;
  line-height: rem(32);

  //head with title
  .screen-head {
    position: relative;
    width: 100%;
    height: rem(62);
    background-color: #F4F5F7;
    padding: 8px 16px;
    display: flex;
    flex-flow: row nowrap;
    flex-shrink: 0;
    align-items: center;

    a.search {
      position: absolute;
      right: 0;
    }

    .icon {
      margin-right: rem(20);
      padding: rem(5);
      width: rem(29);
      height: rem(29);
    }

    @include media-desktop {
      height: rem(85);
      padding: rem(10) rem(20);
      background-color: #fff;
    }

    .content-preview-mode {
      background-color: #FFF0C5;
      color: #736c2f;

      text-transform: uppercase;
      text-align: center;
      font-size: rem(10);
      line-height: rem(14);
      font-weight:500;

      position: absolute;
      width:rem(180);

      left: 50%;
      margin-left:rem(-90);
      top: rem(0);
      border-radius: 0 0 10px 10px;
      padding: rem(2) 0;
      box-shadow: 0 1px 0 rgba(0,0,0,.2);
    }
  }

  //main title
  .screen-title {
    font-size: rem(28);
    line-height: 120%;
    font-weight: 500;
    flex-grow: 1;
    white-space: nowrap;
    text-decoration: none;

    em {
      font-weight:inherit;
      white-space: nowrap;
    }

    color: #000;

    @include media-mobile {
      display: flex;
      flex-flow: column nowrap;
      align-items: center;
      font-weight: 400;
      line-height: rem(18);
      text-align: center;
      em {
        font-size: rem(12);
        font-weight: 700;
      }
      font-size: rem(18);
      padding: rem(8) rem(10) rem(10);
    }
  }

  //back button os-style
  .screen-back {
    display: flex;
    flex-flow: row nowrap;
    flex: 1;

    a,
    button {
      align-items: baseline;
    }

    & > * {
      @extend .screen-title;

      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content:space-between;
      text-align: center;
      text-decoration: none;

      color: #000;
      cursor: pointer;

      &:after {
        content: ' ';
        width: rem(24);
        margin-left:rem(10);
      }

      .icon {
        width: rem(24);
        height: rem(16);
        margin-right: rem(10);
        flex-shrink: 0;

        &-arrow-back {
          width: rem(30);
        }

        .fill {
          fill: #495360;
        }
      }

      @include media-mobile {
        top: rem(-8);
        font-size: rem(18);
      }
    }

    &.title-long > * {
      overflow: hidden;
      font-size: rem(18);
      white-space: normal;
      line-height:20px;
      align-items: center;
    }
  }

  //container with padding
  .screen-content {
    position: relative;
    //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
    flex: 1 1 auto;
    display: flex;
    flex-flow: column nowrap;

    @include media-desktop {
      // white space for footer
      margin-bottom: rem(60);

      &--full-space {
        margin-bottom: 0;

        .screen-container {
          padding: 0;
        }
      }
    }
  }
  .screen-container {
    //padding: rem(20);

    width: 100%;
    height: 100%;
    position: relative;
    //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
    flex: 1 1 auto;

    @include media-desktop {
      position: relative;
      overflow-y: hidden;
    }

    @include media-mobile {
      overflow: hidden;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background: #F4F5F7;
    }

    // DO NOT REMOVE THIS, WILL CAUSE RENDER PROBLEMS ON CHROME
    transform: translateZ(0);

    display: flex;
    flex-flow: column nowrap;
  }

  //text content
  .screen-text {
    font-size: rem(16);
    h1 {
      font-size: rem(28);
      font-weight:500;
      margin-bottom:1em;
    }
    p {
      margin: 0 0 1em;
    }
    strong {
      font-weight: 700;
    }
  }

  //icon toolbar in right corner
  .screen-iconbar {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;

    .iconbar-button {
      display: flex;
      box-sizing: content-box;
      width: rem(24);
      height: rem(24);
      padding:rem(5);
      margin:0 0 0 rem(3);
      justify-content: center;
      align-items: center;

      .icon {
        width: rem(24);
        height: rem(24);
        .fill {
          fill: #000;
        }
        .stroke {
          stroke: #000;
        }
      }
    }
  }

  .screen-topmenu {
    display: flex;
    flex-flow: row nowrap;
    .topmenu-trigger {
      display: none;
    }
    .topmenu-inner {
      display: flex;
      flex-flow:row nowrap;

      li {
        margin: 0 rem(30);
      }
    }
    .topmenu-link {
      font-size:rem(14);
      color: #000;
      text-transform: uppercase;
      text-decoration: none;
      cursor: pointer;
    }
    @include media-mobile(){
      .topmenu-trigger {
        display: block;
        box-sizing: content-box;
        width: rem(24);
        height: rem(24);
        padding:rem(5);

        .icon .fill {
          fill: #000;
        }
      }
      .topmenu-inner {
        visibility: hidden;
        opacity:0;
        transition: all .3s;
        max-height: 0;

        background: #000;
        position: absolute;
        left:0;
        top: rem(65);
        width: 100%;
        z-index:3;
        flex-flow: column nowrap;

        li {
          display: flex;
          align-items: center;
          height:rem(60);
          margin: 0 rem(20);
        }
      }

      .topmenu-link {
        font-size:rem(14);
        display: block;
        width:100%;
        color: #fff;
        text-align: left;
        cursor: pointer;
      }

      &.opened {
        .topmenu-inner {
          visibility: visible;
          opacity:1;
          max-height: rem(500);
        }
        .topmenu-wrapper {
          display: block;
        }
      }
    }
  }

  //logo for Desktop version
  .screen-logo {
    width:rem(64);
    height:rem(54);
    background-size: cover;
    margin-right:rem(20);
  }

  .screen-overlay {
    display: flex;
    flex-flow: column nowrap;
    align-items:center;
    justify-content:center;
    text-align: center;
    position: fixed;
    width: 100%;
    height: 100%;
    left:0;
    top:0;
    z-index: 11;
    background-color: #fff;
    padding: 0 rem(50);

    .overlay-text {
      padding: rem(20);
      font-size: rem(16);
    }
    .icon {
      width:rem(100);
      animation: rotateDevice 3s ease-in-out infinite;
      transform-origin: 50% 50%;

      .fill {
        fill: darken(#E0E6EC, 10%);
      }
    }
    .PrimaryButton {
      min-width:rem(180);
    }
  }
}

%Modal {
  position: absolute;
  top: rem(80);
  left: rem(20);
  right: rem(20);
  bottom: rem(60);
  border-radius: rem(10);
  background: #fff;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  outline: none;

  @include media-mobile("landscape") {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 0;
  }
}

%ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.62);
  z-index: 10;
}

%Button {
  font-size: rem(20);
  line-height: 120%;
  text-decoration: none;
  padding: rem(13) rem(20);
  border-radius: rem(30);
  display: block;
  text-align: center;
  cursor: pointer;
  width: auto;

  transition: all .3s;

  @include media-desktop {
    padding: rem(13) rem(50);
  }

  &:active {
    opacity: 0.8;
  }
}

.PrimaryButton {
  @extend %Button;
  font-weight: bold;
}

.SecondaryButton {
  @extend %Button;
}

%hidden {
  width:100%;
  visibility: hidden;
  opacity:0;
  transition: all .3s;
  position: absolute;
}

%visible {
  width: auto;
  visibility: visible;
  opacity:1;
  position: static;
}

@mixin TouchableFeedback($color: #000) {

  position: relative;
  overflow: hidden;
  transform: translate3d(0, 0, 0);

  &:after {
    content: "";
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    background-image: radial-gradient(circle, $color 10%, transparent 10.01%);
    background-repeat: no-repeat;
    background-position: 50%;
    transform: scale(10,10);
    opacity: 0;
    transition: transform .5s, opacity 1s;
  }

  &:active:after {
    transform: scale(0,0);
    opacity: .2;
    transition: 0s;
  }
}

.BrandsSelectorScreen {
  @extend %Screen;
    .PrimaryButton {
      width: 80%;
      margin: 2em auto;
    }
  }

.development-content {
  border: 1px solid white;
  text-align: center;
  padding: 20px;
  margin: 40px 20px 20px 20px;
}

.collapsed-search {
  .screen-head, .NavBar {
    display: none !important;
  }
}

@mixin NoScrollbar {
  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-button {
    width: 0px;
    height: 0px;
  }
  &::-webkit-scrollbar-thumb {
    background: #e1e1e1;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
  &::-webkit-scrollbar-thumb:active {
    background: #000000;
  }
  &::-webkit-scrollbar-track {
    background: #666666;
    border: 0px none #ffffff;
    border-radius: 50px;
  }
  &::-webkit-scrollbar-track:hover {
    background: #666666;
  }
  &::-webkit-scrollbar-track:active {
    background: #333333;
  }
  &::-webkit-scrollbar-corner {
    background: transparent;
  }
}

@keyframes rotateDevice {
  from {
    transform: rotate(45deg);
  }
  30% {
    transform: rotate(-45deg);
  }
  60% {
    transform: rotate(-45deg);
  }
  to {
    transform: rotate(45deg);
  }
}
