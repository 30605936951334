@import "../utils";

// Infiniti brand theme
$infiniti-primary-color: #000;
$infiniti-secondary-color: #fff;
$infiniti-regular-color: #7c8d98;
$infiniti-contrast-color: #000;
$infiniti-background-color: #f2f2f2;
$infiniti-logo: '../../images/infiniti_logo.png';

/* BRAND FONTS */

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/infinitibrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/infinitibrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/infinitibrand-bold-webfont.svg#infiniti_brandbold') format('svg'); // Legacy iOS
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-bold-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-bold-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../fonts/infinitibrand-bold-webfont.woff2') format('woff2'), // Super Modern Browsers
  url('../fonts/infinitibrand-bold-webfont.woff') format('woff'), // Modern Browsers
  url('../fonts/infinitibrand-bold-webfont.ttf') format('truetype'), // Safari, Android, iOS
  url('../fonts/infinitibrand-bold-webfont.svg#infiniti_brandbold') format('svg'); // Legacy iOS
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-regular-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-regular-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
    url('../fonts/infinitibrand-regular-webfont.woff2') format('woff2'), // Super Modern Browsers
    url('../fonts/infinitibrand-regular-webfont.woff') format('woff'), // Modern Browsers
    url('../fonts/infinitibrand-regular-webfont.ttf') format('truetype'), // Safari, Android, iOS
    url('../fonts/infinitibrand-regular-webfont.svg#infiniti_brandregular') format('svg'); // Legacy iOS
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'infiniti_brand';
  src: url('../fonts/infinitibrand-light-webfont.eot'); // IE9 Compat Modes
  src: url('../fonts/infinitibrand-light-webfont.eot?#iefix') format('embedded-opentype'), // IE6-IE8
  url('../fonts/infinitibrand-light-webfont.woff2') format('woff2'), // Super Modern Browsers
  url('../fonts/infinitibrand-light-webfont.woff') format('woff'), // Modern Browsers
  url('../fonts/infinitibrand-light-webfont.ttf') format('truetype'), // Safari, Android, iOS
  url('../fonts/infinitibrand-light-webfont.svg#infiniti_brandlight') format('svg'); // Legacy iOS
  font-weight: light;
  font-style: normal;
}

// =========================
// Infiniti specific styling
// =========================

div.infiniti {
  & * {
    font-family: 'infiniti_brand', sans-serif;
  }

  // Logo
  .logo, .screen-logo, .terms-logo {
    background-image: url($infiniti-logo);
    background-position: center;
  }

  .screen-logo, .terms-logo {
    width:rem(88);
    height:rem(39);
  }

  .terms-logo {
    flex: 0 0 rem(84);

    @include media-desktop {
      flex: 0 0 rem(95);
    }
  }

  @include media-desktop {
    .screen-logo {
      margin: 0 rem(20) 0 0;
    }
  }

  // Loader styling
  .Loader {
    background-color: $infiniti-primary-color;
  }

  // Splash screen colors
  .TermsOfService, .Vehicles, .BrandsSelectorScreen, .Promo {
    background: $infiniti-background-color;
    color: $infiniti-primary-color;
    .hint, .terms {
      color: $infiniti-primary-color;
    }
    @include media-desktop(){
      .screen-title {
        font-weight:normal;
      }
    }
  }

  // Regular screen colors
  .Guide {
    color: $infiniti-regular-color;

    @include media-desktop() {
      .screen-title {
        color: $infiniti-primary-color;
        font-size: rem(26);
        text-transform: uppercase;
        font-weight:300;
      }
    }
  }

  .Loader {
    background-color: $infiniti-background-color;

    .loader-default {
      & > div {
        background-color: $infiniti-primary-color;
      }
    }

    .loader-ios {
      @keyframes spinner-ios-infiniti {
        0% { background-color: $infiniti-primary-color; }
        100% { background-color: transparent; }
      }
      & > div {
        animation-name: spinner-ios-infiniti;
      }
    }

    .loader-android {
      circle {
        stroke: $infiniti-primary-color;
      }
    }

    &.inverse {
      background-color: $infiniti-secondary-color;
      color: $infiniti-regular-color;
    }
  }

  .NavBar {
    @include media-mobile() {
      .nav-link {
        color: $infiniti-regular-color;

        .icon {
          .active {
            display: none;
          }
          .fill {
            fill: $infiniti-regular-color;
          }
          .stroke {
            stroke: $infiniti-regular-color;
          }
        }

        &.active {
          color: $infiniti-primary-color;

          .icon .active {
            display: inherit;
          }
          .icon .inactive {
            display: none;
          }
          .icon .fill {
            fill: $infiniti-primary-color;
          }
          .icon .stroke {
            stroke: $infiniti-primary-color;
          }
        }
      }
    }
  }

  .VehicleList {
    background: $infiniti-secondary-color;
    h2 {
      color: $infiniti-contrast-color;
    }
    .model {
      color: $infiniti-regular-color;
      .name {
        color: $infiniti-contrast-color;
      }
    }
  }
  .VehicleModelSelector {
    .model-group {
      text-transform: uppercase;
    }
  }

  .VehicleYearSelector {
    background: $infiniti-primary-color;
    .wrapper {
      & > a {
        color: $infiniti-secondary-color;
      }
    }
  }

  .VehicleGroupSlider, .VehicleGroupSelector {
    .group {
      box-shadow: 0 rem(10) rem(20) 0 rgba(0, 0, 0, 0.1);
    }
  }
  .VehicleGroupSelector {
    .group > button {
      text-transform: uppercase;
    }
  }

  //Folder filters desktop/mobile
  .FoldersFilter li.active {
    color: $infiniti-primary-color;

    .icon .stroke {
      stroke: $infiniti-primary-color;
    }
  }
  .FoldersFilterDropdown {
    .dropdown-trigger {
      color: $infiniti-primary-color;
      font-weight: 800;

      .icon .stroke {
        stroke: $infiniti-primary-color;
      }
    }
    &.opened .dropdown-trigger {
      box-shadow:inset 0 -1px #e5e5e5;
    }
  }

  // Buttons
  .PrimaryButton, .SecondaryButton {
    color: $infiniti-primary-color;
    background: transparent;
    font-weight:300;
    text-transform: uppercase;
    border: 1px solid $infiniti-primary-color;
    border-radius: 0;
    font-size:rem(16);
    padding: rem(15) rem(20);
  }

  .ErrorMessage {
    /*.PrimaryButton {
      color: $infiniti-secondary-color;
      background: $infiniti-contrast-color;
    }*/
  }

  .ErrorMessage:not(.inverse) {
    background-color: $infiniti-background-color;
    color: $infiniti-regular-color;
    .icon .fill {
      fill: rgba($infiniti-regular-color, .3);
    }
  }

  .Promo {
    .logo {
      width:rem(120);
      height:rem(120);
    }
    .head {
      h1 {
        font-weight:300;
        text-transform: uppercase;
      }
    }
    .screenshot {
      &.ios {
        background-image: url("../../images/screenshot-infiniti-ios.png");
      }
      &.android {
        background-image: url("../../images/screenshot-infiniti-android.png");
      }
      &:after {
        box-shadow: inset 0 rem(-180) rem(60) rem(-60) rgba($infiniti-background-color, 1);
      }
    }
    .browse {
      color: #666;
    }
  }

  // Terms and conditions styling
  .TermsOfServiceModal {
    .terms-head {
      border-bottom-color: #E5E5E5;
    }
    .terms-title {
      font-weight:300;
      text-transform: uppercase;
      font-size:rem(20);

      p {
        font-weight:300;
        font-size:rem(26);
      }

      @include media-mobile {
        font-size: rem(14);
        line-height:rem(22);

        p {
          font-size:rem(18);
        }
      }
    }
  }
  .Topics {
    @include media-desktop {
      .topic-title {
        font-size:rem(26);
        text-transform: uppercase;
      }
    }

    .topics-breadcrumb,
    .topics-subtitle {
      font-weight:normal;
    }
  }
  .Explore {
    .topics h2 {
      font-size: rem(14);
      line-height: 1.6em;
      text-transform: uppercase;
      margin: rem(-10) 0 rem(25);
      padding-bottom: rem(15);
    }
  }
}
