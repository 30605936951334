$background-color: #13172c;
$body-color: #2a2d3f;
$text-color: #fff;
$text-color-faded: rgba($text-color, 0.5);
$topic-text-color: rgba($text-color, 0.87);
$active-color: #7ACAFF;

@media (prefers-color-scheme: dark) {
  div.container {
    background: $background-color;

    .Vehicles {
      background: $background-color;

      .VehicleYearSelector {
        &__label {
          color: $text-color;
        }

        .wrapper > a {
          background: $body-color;
          color: $text-color;

          &::after {
            color: $text-color;
          }

          &.current {
            background: $active-color;
            color: #000;
          }
        }
      }

      .VehicleModelSelector {
        background: $body-color;
        color: $text-color;

        .model-container {
          .model-group {
            border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          }
        }

        .icon.icon-arrow-left {
          .stroke {
            stroke: $active-color;
          }
        }

        .close:before,
        .close:after {
          background: #fff;
        }
      }

      .VehicleGroupSelector {
        .group {
          background: $body-color;

          & > a {
            color: $text-color;
          }
        }
      }
    }

    .Loader.inverse {
      background: $background-color;

      .status {
        color: #fff;
      }

      .loader-default > div {
        background-color: $active-color;
      }
    }

    .Guide {
      background: $background-color;

      .screen-head {
        background-color: $background-color;

        .screen-back > * {
          color: $text-color;

          .icon.icon-arrow-back .fill {
            fill: #fff;
          }
        }

        .screen-title {
          color: $text-color;
        }

        .icon.icon-search {
          .fill {
            fill: #fff;
          }
        }
      }

      .NavBar {
        background-color: $background-color;

        .nav-link {
          color: $text-color;
        }
        .nav-link.active {
          color: $active-color;
          box-shadow: inset 0 -3px 0px -1px $active-color;
        }
      }

      .Explore {
        .visual-search {
          .hot-spot {
            background: $active-color
          }

          .image-map-container .image-map-selector {
            button {
              background: $body-color;
              border-top: 1px solid rgba(255, 255, 255, 0.1);
              border-right: 1px solid rgba(255, 255, 255, 0.1);

              .button-name {
                color: $active-color;
              }

              .icon {
                .fill {
                  fill: $active-color;
                }

                .stroke {
                  stroke: $active-color;
                }
              }

              &.active {
                background: $active-color;
                border-top: 1px solid $active-color;
                border-right: 1px solid $active-color;

                .button-name {
                  color: #000;
                }

                .icon {
                  .fill {
                    fill: #000;
                  }
                  .stroke {
                    stroke: #000;
                  }
                }
              }
            }
          }
        }
      }

      .TopicsList {
        background: $background-color;

        &__match .Loader {
          background: transparent;
        }

        &.publications {
          li .item-iconbar .icon .fill {
            fill: $active-color;
          }

          li .item-iconbar .icon .fill-inverted {
            fill: $body-color;
          }
        }

        li {
          .item-inner {
            background: $body-color;
          }

          .item-title {
            color: $text-color;
          }

          .item-iconbar {
            .icon {
              .fill {
                fill: #737582;
              }

              &.icon-arrow-left .stroke {
                stroke: $active-color;
              }
            }
          }
        }
      }

      .Topics {
        background: $background-color;

        .screen-container {
          background: $background-color;
        }

        .topics-subtitle {
          color: $text-color;
          box-shadow: none;
        }

        .topic-content {
          li,
          strong,
          .note * {
            color: $topic-text-color;
          }

          p,
          .p {
            color: $topic-text-color;

            &.grid a {
              background: $body-color;
            }
          }
        }

        .video-container .video-markers-container {
          .video-markers {
            background-color: transparent;

            button {
              color: $topic-text-color;
            }
          }
        }

        .SearchInput__inner-container {
          background: transparent;

          input {
            color: $text-color;

            &::placeholder {
              color: #fff;
            }
          }

          .fill {
            fill: #fff;
          }

          & + .topics-subtitle hr {
            background: rgba(255, 255, 255, 0.15);
          }
        }

        .SortSelector {
          &__label {
            color: $text-color;
          }

          &__btn {
            color: $active-color;
          }

          .Menu {
            background: $background-color;

            .menu-item {
              color: $text-color;
            }
          }
        }

        .PdfMatches {
          &__header {
            box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.15);
          }

          &__search-result-page {
            color: $text-color-faded;
          }

          &__search-result-match {
            color: $topic-text-color;
          }
        }

        .PdfViewer {
          .SearchResults {
            background: $body-color;

            &__item {
              border: 1px solid rgba(255, 255, 255, 0.15);
            }

            &__item--selected {
              border: 1px solid $active-color;
            }

            &__item-content {
              color: $text-color;
            }

            &__item-page {
              color: $text-color-faded;
            }

            .CloseBtn .fill {
              fill: #fff;
            }
          }

          .PdfPreview {
            background: $body-color;

            .Loader {
              background: transparent;
            }
          }
        }

        .topics-breadcrumb li a {
          color: $text-color;
        }
      }
    }

    .topics {
      background: $background-color;

      h2 {
        color: $text-color;
      }
    }

    .ErrorMessage {
      h1,
      .message {
        color: $text-color;
      }
    }
  }

  .screen-head .ScreenHead .ScreenHead__search {
    background-color: transparent;

    .ScreenHead__search-placeholder {
      color: $text-color;
    }
  }
}
