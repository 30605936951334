@import 'common';
$hotspot-color: #0073BF;
$imagemap-selector-color: #0073BF;

.Explore {

  @extend %Screen;

  .visual-search {
    display: flex;
    flex: 1 0 auto;
    flex-grow: 0;

    .image-map-container {
      width: 100%;
      position: relative;

      @keyframes fadeIn {
        from {
          opacity: 0;
        }
        to {
          opacity: var(--toOpacity);
        }
      }

      .hot-spot {
        position: absolute;
        display: block;
        background-color: $hotspot-color;
        cursor: pointer;
        --toOpacity: 0.6;
        opacity: var(--toOpacity);
        transition: all .2s;
        animation: fadeIn 1s linear;
        &.active {
          --toOpacity: 0.8;
          animation: jumpingSpot 1.3s infinite, fadeIn 1s linear;
          opacity: var(--toOpacity);
        }

        @include media-desktop() {
          width: rem(40);
          height: rem(40);
          margin-top: rem(-20);
          margin-left: rem(-20);
          border-radius: rem(20);
          &.active {
            width: rem(60);
            height: rem(60);
            margin-top: rem(-30);
            margin-left: rem(-30);
            border-radius: rem(30);
          }
        }

        @include media-mobile() {
          width: rem(40);
          height: rem(40);
          margin-top: rem(-20);
          margin-left: rem(-20);
          border-radius: rem(20);
          &.active {
            width: rem(60);
            height: rem(60);
            margin-top: rem(-30);
            margin-left: rem(-30);
            border-radius: rem(30);
          }
        }
      }

      .image-map {
        position: relative;
        user-select: none;
        overflow: hidden;
        //-webkit-overflow-scrolling: touch;

        @include media-desktop {
          overflow: hidden;
        }

        &:focus {
          outline-style:none;
          box-shadow:none;
          border-color:transparent;
        }

        .image-map-inner {
          display: block;
          width: 100%;
          transform-origin: 0 0;

          img {
            display: block;
            width: 100%;
          }
        }

        .image-map-overlay {
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
          justify-content: space-around;
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          background: rgba(#000, .5);
          pointer-events:none;
          transition: .2s all;
          animation: fadeIn 1s linear;

          &.hidden {
            opacity:0;
            visibility: hidden;
          }


          .image-map-gesture {
            color: #fff;
            font-size: rem(14);
            display: flex;
            flex-flow: column nowrap;
            align-items:center;
          }

          .icon {
            width: rem(75);
            margin-bottom: rem(10);
            .fill {
              fill: #fff;
            }

            &.icon-gesture-pinch {
              animation: pinchIcon 2s infinite;
            }
            &.icon-gesture-pan {
              animation: panIcon 2s infinite;
            }
          }
        }

        &.zoom {
          //@include NoScrollbar;
          //overflow: scroll;

          &.zoom-2x .hot-spot {
            transform: scale(0.8);

            &.active {
              transform: scale(0.7);

              animation-name: jumpingSpotZoom2x;
            }
          }
          &.zoom-3x .hot-spot {
            transform: scale(0.7);

            &.active {
              transform: scale(0.6);

              animation-name: jumpingSpotZoom3x;
            }
          }

          /*.image-map-placeholder {
            display: block;
          }*/
        }
      }
      .image-map-zoom {
        display: none;
        align-items: center;
        justify-content: center;
        position: absolute;
        width:rem(50);
        height:rem(50);
        bottom: rem(100);
        right: rem(10);
        background: white;
        border-radius: 100%;
        box-shadow: 0 3px 3px rgba(0,0,0,.2);

        .icon {
          width: rem(20);
          height: rem(20);
          .fill {
            fill: #010101;
          }
        }

        @include media-mobile {
          display: flex;
        }
      }

      @keyframes slideTop {
        0% {
          top: -100px;
          z-index: -1;
          opacity: 0;
        }
        95% {
          z-index: -1;
        }
        100% {
          top: 0;
          z-index: 1;
          opacity: 1;
        }
      }

      .image-map-selector {
        display: flex;
        align-items: stretch;
        flex-direction: row;
        position: relative;
        animation: slideTop .6s ease-out;
        max-height: rem(85);

        .button-name {
          font-size: rem(12);
          font-weight: 400;
          line-height: rem(14);
          color: #0073BF;
        }

        button {
          display: flex;
          flex-flow: column;
          align-items: center;
          justify-content: center;
          //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
          flex: 1 1 auto;
          text-align: center;
          cursor: pointer;
          padding: 3% 1%;
          margin: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-color: #fff;

          border: none;
          border-top: 1px solid rgba(0, 0, 0, .1);
          border-right: 1px solid rgba(0, 0, 0, .1);

          .icon {
            //warning! check to use only flex-basis: auto not flex-basis: 0 for IE11 support
            flex: 1 1 auto;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 6%;
            width: rem(40);

            .fill {
              fill: #0073BF;
            }

            .stroke {
              stroke: #0073BF;
            }

            &.icon-explore360 {
              width: rem(50);
            }

            // TODO: remove when proper icons provided
            &.icon-exploreHatch,
            &.icon-exploreBack,
            &.icon-explorePickup {
              width: rem(65)
            }
          }

          &.active {
            background-color: $imagemap-selector-color;
            border-top: 1px solid $imagemap-selector-color;
            border-right: 1px solid $imagemap-selector-color;

            .button-name {
              color: #fff;
            }

            .icon {
              .stroke {
                stroke: #FFF;
              }

              .fill {
                fill: #fff;
              }
            }
          }
        }
      }

    }
  }

  .topics {
    display: flex;
    flex-flow: column nowrap;
    flex: 1 0 auto;
    position: relative;
    animation: slideTop .6s ease-out;

    h2 {
      font-size: rem(20);
      font-weight: bold;
      line-height: rem(50);
      text-align: left;
      color: #000000;
      border-bottom: 1px solid #767676;
      margin-bottom: rem(20);
    }
  }

  @include media-desktop() {
    .screen-container {
      display: flex;
      flex-direction: row;
      padding: rem(40) rem(50) 0 rem(115);

      .visualSearch {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 50%;
      }
      .topics {
        padding-left: rem(60);
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 50%;
      }
    }
  }

}

@keyframes jumpingSpot {
  from {
    transform: scale(0.95);
  }
  50% {
    transform: scale(1.05);
  }
  to {
    transform: scale(0.95);
  }
}


@keyframes jumpingSpotZoom2x {
  from {
    transform: scale(0.68);
  }
  50% {
    transform: scale(0.72);
  }
  to {
    transform: scale(0.68);
  }
}


@keyframes jumpingSpotZoom3x {
  from {
    transform: scale(0.58);
  }
  50% {
    transform: scale(0.62);
  }
  to {
    transform: scale(0.58);
  }
}

@keyframes pinchIcon {
  from {
    transform: scale(1) rotate(-5deg);
    opacity: .9;
  }
  50% {
    transform: scale(1.1) rotate(5deg);
    opacity: 1;
  }
  to {
    transform: scale(1) rotate(-5deg);
    opacity: .9;
  }
}

@keyframes panIcon {
  0% {
    transform: translate(0, 0);
    opacity: .9;
  }
  50% {
    transform: translate(rem(7), rem(3));
    opacity: 1;
  }
  100% {
    transform: translate(0, 0);
    opacity: .9;
  }
}
